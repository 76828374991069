<template>
  <div class="homepage">
    <Hero :leadText="leadText" :img="heroImg" />
    <ProductFamilies/>
    <!-- <Form/> -->
    <!-- <gallery-section/>
    <OurExperienceSection/> -->
    <HeroSliderSection :slider="homepageSlider" />
    <!-- <ReviewsSection/> -->
    <div v-if="homepageSlider && homepageSlider.earthlings_slider && homepageSlider.earthlings_slider[0]" class="container-fluid mt-3 home-slider" style="background-position: center center; background-size: cover" :style="{'background-image': 'url('+imgFile(homepageSlider)}">
      
        <div class="container-fluid">
          <h4>{{homepageSlider.earthlings_slider[0].name}}</h4>
          <div class="row" v-for="(it,index) in homepageSlider.earthlings_slider[0].earthlings_slider_item" :key="it.id" :style="{'margin-top' : (index > 0) ? '150px' : '20px'}" >
            <div class="col-md-4" v-if="index > 1"></div>
            <div class="col-md-8">
              <h3 >{{it.name}}</h3>
            </div>
          </div>
          <div class="row" style="margin-top: 150px; padding-bottom: 150px">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <router-link class="linkbutton" :to="{name: 'AboutUs_'+selectedLangCode}">{{$store.state.translation.story_text}}</router-link>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
// import ReviewsSection from "@/components/ReviewsSection";
// import HeroPlugin from "@/components/HeroPlugin";
import Hero from "@/components/Hero";
// import Form from "@/components/Form";
import ProductFamilies from "@/components/ProductFamilies";
// import About from "@/components/About";
// import OurExperienceSection from "@/components/OurExperienceSection";
import HeroSliderSection from "@/components/carouselSection";
// import GallerySection from "@/components/gallerySection";

export default {
  data(){
    return {
      pageContent: null,
      leadText: null,
      heroImg: null,
      homepageSlider: null
    }
  },  
  components: {
    // GallerySection, 
    HeroSliderSection, ProductFamilies,
    /*HeroPlugin,*/ 
    Hero, 
    // Form
    // OurExperienceSection, 
    // ReviewsSection
  },
  methods:{
    async getCurrentPageContent(){
      let _this = this
      const content = await _this.getPage('1')
      _this.pageContent = content[0]
      _this.leadText = _this.getLeadText()
      _this.heroImg = _this.getHeroBgr()
      _this.homepageSlider = _this.getHomePegeSlider()
    },
    getLeadText(){
      return this.pageContent.earthlings_page_block.find( item => parseInt(item.id) === 1).short_description
    },
    getHeroBgr(){
      return this.pageContent.earthlings_page_block.find( item => parseInt(item.id) === 1).image
    },
    getHomePegeSlider(){
      return this.pageContent.earthlings_page_block.find( item => parseInt(item.id) === 10)   
       },
       imgFile (block){
            return block.image ? this.apiUrl + '/' + block.image.path +  block.image.file_name : ""
        },
  },  
  created(){
    this.getCurrentPageContent()
   
  }
}
</script>
