<template>
    <div>
        <div class="hero" :style="{ 'background-image' : 'url(' + imgFile + ')' }">
            <div class="d-none justify-content-center align-items-center h-100 d-lg-flex">
                <h1 class="text-white hero__title text-center">
                    {{leadText}}
                </h1>
            </div>
        </div>
        <div class="d-flex d-lg-none hero__outer justify-content-center align-items-center">
            <h1 class="text-white text-center py-5 ml-2 mr-2">
                {{leadText}}
            </h1>
        </div>
    </div>
</template>

<script>
//import 'vue-slider-component/theme/antd.css'

export default {
    props:['leadText', 'img'],
    computed:{
        imgFile (){
            return this.img ? this.apiUrl + '/' + this.img.path +  this.img.file_name : ""
        }
    }
}
</script>
