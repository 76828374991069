<template>
    <router-link :to="to" class="product__families">
        <div class="d-flex justify-content-between flex-column">
            <div class="top_section d-flex flex-row align-items-center">
                <h4 class="m-0">{{product.name}}</h4>
                <p class="mb-0 ml-3 price" >{{getPrice(product).fprice}}</p>
               
            </div>
            <div>
                <p class="desc">{{product.short_description}}</p>
                <div class="image_section p-5" :style="{'background-image': 'url('+imgFile +')'}"></div>
                <span class="text-white link text-decoration-none d-flex justify-content-between align-items-center py-2">View<img src="../../assets/icons/white-arrow.svg" class="img-fluid mr-2"></span>
            </div>
        </div>
    </router-link>
</template>
<script>
export default {
    props:{
        product : {
            required: true,
            type: Object
        },
        to: {
            required: true,
            type: Object
        },
       
    },
    
    computed:{
        imgFile (){
            return this.product.list_image  ? this.apiUrl + '/' + this.product.list_image.path +  this.product.list_image.file_name : ""
        }
    }
    
}
</script>