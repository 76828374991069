<template>
    <div class="container">
        <h4 class="families_title text-uppercase pb-5">Choose a model</h4>
        <div class="row product_families_border">
            <div class="col-md-4" v-for="pf in product_families" :key="pf.id" >
                <ProductListItem :product="pf" :to="{name : 'Productfamily_'+selectedLangCode, params:{family: pf.slug}}" />
            </div>
        </div>
    </div>
</template>
<script>
import ProductListItem from './elements/ProductListItem.vue';
export default {
  components: { ProductListItem },
    data(){
        return {
            product_families: []
        }
    },
    methods:{
        getProductFamilies(){
            this.get('earthlings_product_family',{
                relations: 1,
                where: [
                    ['where','status',1]
                ],
                order_by:[['sort_order','asc']]
                    
            }).then(res=>{
                this.product_families = res.data.earthlings_product_family;
                this.$forceUpdate();
            })
        }
    },
    created(){
        this.getProductFamilies();
    }
}
</script>